import { useEffect } from "react";
import { Link } from "react-router-dom";

import style from "../css/Popup.module.css";
import exit from "../image/popup/exit.svg"
import logo from "../image/component/header_logo.svg";

export default function Popup({setPopup, popup}) {
    function closeToday() {
        const expire = new Date();
        const expireDate = expire.getDate();

        localStorage.setItem('VisitCookie', expireDate);
        setPopup(false);
    }

    return(
        <div className={style.popupContainer}>
            <div className={style.popupHeader}>
                <img src={exit} alt="popup-exit" onClick={() => setPopup(false)}></img>
            </div>
            <div className={style.popupBody}>
                <div className={style.popupCnt}>
                    {/*<img src={logo} alt="logo" id={style.bodyLogo} />*/}
                     
                    <p>주주명부폐쇄기간 또는 기준일 설정 공고</p>
                    <div className={style.popupTxt}>
                    당사 정관 규정 17조에 따라 제47기 정기주주총회 개최를 위한 권리주주 확정<br/> 
                    </div>
                    <div className={style.popupInfo}>
                        <div>1. 주주확정 기준일 : 2024년 12월 31일</div>
                        <div>2. 명의개서정지기간 : 2024년 01월 01일 ~ 2024년 01월 15일</div>
                        
                    </div>
                    <div className={style.popupAdd}>
                    <br/>
                        <span>2024년 12월 13일</span>
                        <br/>
                        <span>한주에이알티 주식회사</span>
                        <span>서울특별시 강동구 고덕비즈밸리로2가길 21, 12층</span>
                        <br/>
                        <span>대표이사 윤 정 호</span>
                    </div>
                </div>
            </div>
            <div className={style.popupFooter}>
                <span onClick={closeToday}>오늘하루 그만보기</span>
            </div>
        </div>
    )
}